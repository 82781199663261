<template>
  <div style="margin:50px auto;display:block;">
      <div>
          <p style="padding:25px 0px 25px 0px;font-size:30px;color:#333;font-weight:600;text-align:center">
          Oficina Global Valencia
      </p>
      <div style="margin:50px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>
      <p class="text-center subtitle" style="color:#ccc">
          Dirección 
      </p>
      <p class="text-center pb-5" style="color:#333;font-style:italic;">
          C.C Montebianco, Piso 3, Oficina 30, Av. Bolivar Norte, Municipio Valencia.
      </p>
      <v-layout row justify-center>
          <v-flex xs12 md6>
                <v-img src="@/assets/oficina.jpg" height="450"></v-img>
          </v-flex>
          <v-flex xs12 md6>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d825.4779230276947!2d-68.01019753734806!3d10.208494342712743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e805da133b285d7%3A0xe6c349b19c77d143!2sCc%20montebianco!5e0!3m2!1ses!2sve!4v1624167214106!5m2!1ses!2sve" 
              height="450" style="width:100%;border:0;" allowfullscreen="" loading="lazy"></iframe>
          </v-flex>
      </v-layout>
      </div>




      <div style="margin-top:100px;">
          <p style="padding:25px 0px 25px 0px;font-size:30px;color:#333;font-weight:600;text-align:center">
          Oficina Global Morrocoy
      </p>
      <div style="margin:50px auto;display:block;width:50px;border-top:solid 4px #D41700"></div>
      <p class="text-center subtitle" style="color:#ccc">
          Dirección 
      </p>
      <p class="text-center pb-5" style="color:#333;font-style:italic;">
          next...
      </p>
      <v-layout row justify-center>

          <v-flex xs12 md6>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d396371.68134220643!2d-94.85590839354082!3d39.0915837115005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87c0f75eafe99997%3A0x558525e66aaa51a2!2sKansas%20City%2C%20Misuri%2C%20EE.%20UU.!5e0!3m2!1ses!2sve!4v1620981708956!5m2!1ses!2sve" 
              height="450" style="width:100%;border:0;" allowfullscreen="" loading="lazy"></iframe>
          </v-flex>
          <v-flex xs12 md6>
                <v-img src="@/assets/oficina.jpg" height="450"></v-img>
          </v-flex>
          
      </v-layout>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>

<style>

</style>